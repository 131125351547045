<script>
import LazyHydrate from 'vue-lazy-hydration'
export default {
	metaInfo() {
		let metaInfo = {}
		if (this.$route.meta.pageTitle) {
			metaInfo.title = this.$route.meta.pageTitle
		}
		if (this.$route.meta.description) {
			metaInfo.meta = [{ vmid: 'd', name: 'description', content: this.$route.meta.metaDescription }]
		}
		return metaInfo
	},
	components: {
		LazyHydrate,
	},
	data() {
		return {
			items: [],
		}
	},
	watch: {
		$route() {
			this.$meta().refresh()
			this.$trackers.pageView()
		},
	},
	async resolveData(context, to, from, next) {
		const { ssr } = context

		try {
			if (ssr) {
				let items = await ssr.loadViewData('dynamicPage', to.meta.dynamicPageKey)
				ssr.shouldCache = true
				return { items }
			} else {
				const { apis, store } = context
				const { data } = await apis.shopApi.get('/dynamic-page-data', {
					loading: (v) => store.set('shop/routeLoading', v),
					query: { dynamicPageKey: this.$route.meta.dynamicPageKey },
				})
				return { items: data.items }
			}
		} catch (err) {
			next(err)
		}
	},
	mounted() {
		this.$meta().refresh()
		this.$trackers.pageView()
	},
	// async fetch() {
	// 	await this.$shopApi.get('/dynamic-page-data', {
	// 		query: { dynamicPageKey: this.$route.meta.dynamicPageKey },
	// 		loading: (v) => (this.loading = v),
	// 		onSuccess: ({ data }) => {
	// 			this.items = data.items
	// 		},
	// 	})
	// },
	// fetchKey() {
	// 	return `dynamic_page_data_${this.$route.meta.dynamicPageKey}`
	// },
	// trackPageView: true,
}
</script>

<template>
	<LazyHydrate when-idle>
		<DynamicPageItems :items="items" :css-vars-key="$route.meta.dynamicPageKey" />
	</LazyHydrate>
</template>

